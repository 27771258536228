import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, Modal } from 'react-bootstrap';
import { ThemedButton } from '../Themed';

const ConfirmCancelModal = ({
  show,
  message,
  onCancel,
  onConfirm,
}) => {
  const handleCancel = () => onCancel && onCancel();

  const handleConfirm = () => onConfirm && onConfirm();

  return (
    <Modal
      show={show}
      centered={true}
      onHide={handleCancel}
    >
      <Modal.Body style={{ paddingBottom: 80 }}>
        <div className="p-4">
          <h2 className="text-center p-4">{message}</h2>
        </div>

        <ButtonGroup
          size="lg"
          style={{
            position: 'absolute',
            left: 0,
            bottom: 0,
            width: '100%',
          }}
        >
          <Button
            variant="dark"
            onClick={handleCancel}
          >
            No
          </Button>
          <ThemedButton onClick={handleConfirm}>
            Yes
          </ThemedButton>
        </ButtonGroup>
      </Modal.Body>
    </Modal>
  );
};

ConfirmCancelModal.propTypes = {
  show: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default ConfirmCancelModal;