import React from 'react';
import Numeral from 'numeral';
import { compose } from 'recompose';
import { Button, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';

import QuantityButton from './QuantityButton';
import { ORDER_STATUS_CANCELLED, ORDER_STATUS_ERROR, TEXT_CANCEL } from '../common/Constants';
import { PosApi } from '../common';
import { withCheck, withMetadata, withSettings, withToast } from '../common/providers';
import { BottomButtonGroup, ConfirmCancelModal, Container, Loading, TopNavBar } from '../common/components';

import QuantityBadge from '../ordering/QuantityBadge';
import './Checkout.scss';

const Checkout = ({
  check,
  history,
  products,
  removeItem,
  updateCheck,
  updateQuantity,
  settings,
  toast,
}) => {	
  const [showWorking, setShowWorking] = React.useState(false);
  const [showCancel, setShowCancel] = React.useState(false);
  const [removeHashId, setRemoveHashId] = React.useState();
  const title = `Order Summary (${Numeral(check.subtotal).format('$0,0.00')})`;

  const handleCancel = () => {
    gotoCheckout(`?status=${ORDER_STATUS_CANCELLED}`);
  }

  const handlePay = async () => {
    setShowWorking(true);

    try {
      // TODO: Do not send to kitchen, just post
      const order = await PosApi.postCheck(check, settings);

      updateCheck({
        ...order,
        sent: true,
      });

      history.push('/pay');
    }
    catch (err) {
      console.error(err);
      gotoCheckout(`?status=${ORDER_STATUS_ERROR}`);
    }
  };
  
  const gotoCheckout = (query) => history.push(`/thankyou${query}`);

  const handleQuantityChanged = (hashId, item, quantity) => {
    if (quantity === 0) {
      return setRemoveHashId(hashId);
    }

    let product = products[item.id];

    updateQuantity(hashId, quantity, product.price);
  };

  const handleRemove = () => {
    removeItem(removeHashId);
    setRemoveHashId();
  };

  const bottomButtons = [
    {
      name: 'Cancel Order',
      onClick: () => setShowCancel(true),
    },
    {
      onClick: handlePay,
      primary: true,
      content: (
        <>
          Continue <QuantityBadge quantity={check.totalQuantity} />
        </>
      ),
    },
  ];

  return (
    <Container style={{ paddingBottom: 100 }}>

      <div className="d-flex justify-content-start align-items-center mb-4">
        <Button
          variant="link"
          className="mr-4 mb-0 p-0 rounded-circle font-weight-bolder text-dark h3"
          onClick={() => history.goBack()}
        >
          <i className="fa fa-chevron-left 2x" />
        </Button>
        <TopNavBar title={title} buttons={[]} />
      </div>

      <Loading icon="list" text="Updating order" show={showWorking} />

      {Object.keys(check.items).map((hashId) => {
        const item = check.items[hashId];

        return (
          <Card
            key={hashId}
            className="mb-4 border-0 shadow-sm"
          >
            <Card.Body className="p-lg-3" style={{ fontSize: '1rem' }}>
              <div className="d-flex justify-content-between align-items-center px-3">

                <p className="mb-0 pr-2">{item.name}</p>

                <div className="d-flex align-items-center">
                  <span className="mb-0 mr-md-3 pr-1 pl-2 text-right" style={{ display: 'inline-block', width: 80, textAlign: 'left' }}>{Numeral(item.price).format('$0,0.00')}</span>
                  <div className="d-flex flex-row align-items-center" style={{ padding: '0 5px' }}>

                    <QuantityButton onClick={() => handleQuantityChanged(hashId, item, item.quantity - 1)}>
                      <i className="fa fa-minus fa-fw" />
                    </QuantityButton>

                    <div className="mx-1 mx-sm-3" style={{ width: 25, textAlign: 'center' }}>{item.quantity}</div>

                    <QuantityButton onClick={() => handleQuantityChanged(hashId, item, item.quantity + 1)}>
                      <i className="fa fa-plus fa-fw" />
                    </QuantityButton>
                  </div>

                </div>
              </div>

              {Object.keys(item.forcedMods).map(groupId =>
                Object.keys(item.forcedMods[groupId]).map(modId =>
                  <p key={modId} className="text-muted ml-4 mb-1 pl-2">
                    {item.forcedMods[groupId][modId].name} &nbsp;
                    {!!item.forcedMods[groupId][modId].price && 
                      <span>({Numeral(item.forcedMods[groupId][modId].price).format('$0,0.00')})</span>
                    }
                  </p>
                )
              )}

              {Object.keys(item.exceptionMods).map(groupId =>
                Object.keys(item.exceptionMods[groupId]).map(modId =>
                  <p key={modId} className="text-muted ml-4 mb-1 pl-2">
                    {item.exceptionMods[groupId][modId].name} &nbsp;
                    {!!item.exceptionMods[groupId][modId].price && 
                      <span>({Numeral(item.exceptionMods[groupId][modId].price).format('$0,0.00')})</span>
                    }
                  </p>
                )
              )}

              {item.notes &&
                <p className="text-muted ml-4 mb-1 pl-2">
                  Notes
                  <OverlayTrigger
                    placement="bottom"
                    trigger="click"
                    overlay={<Tooltip><p style={{ whiteSpace: 'pre-wrap'}}>{item.notes}</p></Tooltip>}
                  >
                    <i className="ml-2 fa fa-info-circle" style={{ cursor: 'pointer' }} />
                  </OverlayTrigger>
                </p>
              }
            </Card.Body>
          </Card>

        );
      })}

      <ConfirmCancelModal
        message="Are you sure you want to remove this item?"
        show={!!removeHashId}
        onConfirm={handleRemove}
        onCancel={() => setRemoveHashId()}
      />

      <ConfirmCancelModal
        message={TEXT_CANCEL}
        show={showCancel}
        onConfirm={handleCancel}
        onCancel={() => setShowCancel(false)}
      />

      <BottomButtonGroup buttons={bottomButtons} />

    </Container>
  );
};

export default compose(
  withCheck,
  withMetadata,
  withSettings,
  withToast,
)(Checkout);