import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ListGroup } from 'react-bootstrap';
import { ApplePayMark, GooglePayMark, LoyaltyMark } from '../common/images';

const ListGroupItem = styled(ListGroup.Item)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
`;

const IconContainer = styled.div`
  width: 50px;
  margin-right: .5rem
`;

const Image = styled.img`
  width: 100%;
`;

const PaymentMethods = ({
  tenderTypes,
  onSelect,
  onInquirySelect,
}) => {
  const ApplePay = window.FreedomPay.Apm.ApplePay;

  const { userAgent } = navigator;
  const showGooglePay = !!userAgent.match(/android/i);
  const showApplePay = ApplePay.isSupported() && ApplePay.canMakePayments();

  const renderPaymentMethod = (text, paymentType, { image, icon }) => {
    return (
      <ListGroupItem action onClick={() => onSelect(paymentType)}>
        <LabelContainer>
          <IconContainer>
            {image && <Image src={image} alt={text} />}
            {icon && <i className={`fa fa-2x fa-${icon}`} />}
          </IconContainer>
          {text}
        </LabelContainer>

        <i className="fa fa-chevron-right" />
      </ListGroupItem>
    );
  };

  const renderInquiryMethods = (text, tenderTypeId, { key, image, icon }) => {
    return (
      <ListGroupItem action key={key} onClick={() => onInquirySelect(tenderTypeId)}>
        <LabelContainer>
          <IconContainer>
            {image && <Image src={image} alt={text} />}
            {icon && <i className={`fa fa-2x fa-${icon}`} />}
          </IconContainer>
          {text}
        </LabelContainer>

        <i className="fa fa-chevron-right" />
      </ListGroupItem>
    );
  };

  return (
    <>
      <ListGroup className="shadow-sm">
        {renderPaymentMethod('Credit Card', 'Card', { icon: 'credit-card' })}
        
        {showGooglePay && renderPaymentMethod('Google Pay', 'GooglePay', { image: GooglePayMark })}

        {showApplePay && renderPaymentMethod('Apple Pay', 'ApplePay', { image: ApplePayMark })}

        {tenderTypes.supported?.PlayerRewards?.map(ttId =>
            renderInquiryMethods(tenderTypes[ttId].name, ttId, { image: LoyaltyMark, key: ttId })
        )}

        {tenderTypes.supported?.PMS &&
          renderInquiryMethods(
            tenderTypes[tenderTypes.supported.PMS].name,
            tenderTypes.supported.PMS,
            { icon: 'building' },
        )}

        {tenderTypes.supported.Other && renderInquiryMethods(
          tenderTypes[tenderTypes.supported.Other].name,
          tenderTypes.supported.Other,
          { icon: 'tag' }
        )}
      </ListGroup>
    </>
  );
};

PaymentMethods.propTypes = {
  tenderTypes: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  onInquirySelect: PropTypes.func.isRequired,
};

export default PaymentMethods;