import React from 'react';
import PropTypes from 'prop-types';
import Numeral from 'numeral';
import styled from 'styled-components';
import { compose } from 'recompose';
import { Accordion, Card, Form, Modal } from 'react-bootstrap';

import { withCheck, withSettings } from '../common/providers';
import { BottomButtonGroup } from '../common/components';
import Modifiers from './Modifiers';
import db from '../common/providers/MetadataProvider/metadataDb';

const Name = styled.h3`
  color: ${props => props.theme.primaryColor};
  padding-right: 1rem;
`;

const CloseContainer = styled.div`
  position: absolute;
  top: ${props => props.hasimage === 'true' ? '1rem' : '1.5rem'};
  right: ${props => props.hasimage === 'true' ? '1.2rem' : '1.5rem'};
  color: #fff;
`;

const TimesGlyph = styled.span`
  color: ${props => props.hasimage === 'true' ? '#fff' : '#000' };
`;

const ProductSelectModal = ({
  orderByWeight,
  product,
  productGroup,
  forcedModifierGroups = {},
  exceptionModifierGroups,
  notesDisabled,
  show,
  onAdd,
  onHide,
}) => {	
  const [forcedMods, setForcedMods] = React.useState({});
  const [exceptionMods, setExceptionMods] = React.useState({});
  const [notes, setNotes] = React.useState('');
  const [upcharge, setUpcharge] = React.useState(0);
  const [image, setImage] = React.useState();
  const productForcedModGroups = product.forcedModifiersGroups || {};
  const notesRef = React.useRef();

  const hasNewLine = !!notes.match(/\n/);

  const handleAddProduct = () => {
    onAdd(forcedMods, exceptionMods, upcharge, notes.trim());
  };

  const handleForcedModToggle = (modGroupId, modId, mod) => {	  
    const curMods = { ...forcedMods };

    if (curMods[modGroupId] && curMods[modGroupId][modId]) {
      delete curMods[modGroupId][modId];
    } else {
      Object.assign(curMods, {
        [modGroupId]: {
          ...forcedMods[modGroupId],
          [modId]: mod,
        },
      });
    }

    setForcedMods(curMods);
    setUpcharge(upcharge + mod.price);
  };

  const handleExceptionModToggle = (modGroupId, modId, mod) => {
    let curMods = { ...exceptionMods };

    // If select, remove from list
    if (curMods[modGroupId] && curMods[modGroupId][modId]) {
      delete curMods[modGroupId][modId];
    } else {
      // Add to list
      curMods = {
        ...exceptionMods,
        [modGroupId]: {
          ...exceptionMods[modGroupId],
          [modId]: mod,
        },
      };
    }

    setExceptionMods(curMods);
    setUpcharge(upcharge + mod.price);
  };

  const hasRequiredMods = React.useMemo(() => {
    const requiredGroupIds = Object.keys(productForcedModGroups);

    // No required mods
    if (!requiredGroupIds.length) {
      return true;
    }

    const firstNonFulfilledGroup = requiredGroupIds.find(groupId => {

      // Hasn't been selected
      if (!forcedMods[groupId]) {
        return true;
      }
      
      // Min of the group not met
      const { min } = forcedModifierGroups[groupId];
      return Object.keys(forcedMods[groupId]).length < min;
    });

    // All required mods are met if there aren't any non-fulfilled groups
    return !firstNonFulfilledGroup;

  }, [forcedMods, forcedModifierGroups, productForcedModGroups]);

  const renderCloseButton = (hasImage) => (
    <CloseContainer hasimage={hasImage.toString()} onClick={onHide}>
      <button type="button" className="close" aria-label="Close">
        <TimesGlyph aria-hidden="true" hasimage={hasImage.toString()}>&times;</TimesGlyph>
      </button>
    </CloseContainer>
  );

  const bottomButtons = [
    {
      variant: 'dark',
      onClick: () => onHide(),
      content: 'Cancel',
    },
    {
      variant: 'dark',
      primary: true,
      disabled: !hasRequiredMods || hasNewLine,
      onClick: handleAddProduct,
      content: 'Add To Cart',
    }
  ];

  React.useEffect(() => {
    const fetchImage = async () => {
      const prod = await db.products
        .where('id')
        .equals(product.id)
        .first();

      setImage(prod.image);
    };

    fetchImage();
  }, [product.id]);

  return (
    <Modal show={show} onHide={onHide} size="md">

      {image && renderCloseButton(true)}

      {image && <Card.Img variant="top" src={image} alt={product.name} />}

      <Modal.Body className="p-4" style={{ paddingBottom: 80, position: 'relative' }}>

        {!image && renderCloseButton(false)}

        <div className="mb-4">
          <Name>
            <span className="font-weight-bolder mr-2">
              {product.name}
            </span>
            <small>{Numeral(product.price).format('$0,0.00')}</small>
          </Name>

          <p>{product.description}</p>
        </div>

        <Modifiers
          columns={4}
          product={product}
          modGroupsOrderByWeight={orderByWeight}
          forcedModifiersGroups={forcedModifierGroups}
          productGroup={productGroup}
          exceptionModifiersGroups={exceptionModifierGroups}
          onForcedModToggle={handleForcedModToggle}
          onExceptionModToggle={handleExceptionModToggle}
        />

        {!notesDisabled &&
          <Card className="border-0 mb-3">
            <Accordion>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="0"
                onClick={() => setTimeout(() => notesRef.current.focus(), 500)}
                style={{ cursor: 'pointer' }}
                className="border-0"
              >
                <h5 className="m-0 p-0">Notes</h5>
              </Accordion.Toggle>

              <Accordion.Collapse eventKey="0">
                <div>
                  <Form.Control
                    as="textarea"
                    ref={notesRef}
                    name="notes"
                    placeholder="Additional charge might be applied for special requests"
                    isInvalid={hasNewLine}
                    maxLength={128}
                    style={{ fontSize: '11pt' }}
                    value={notes}
                    onChange={e => setNotes(e.target.value)}
                  />
                  <div className="d-flex justify-content-between my-2">
                    <small className="text-danger">{hasNewLine && 'Notes cannot contain new line'}</small>
                    <small className="text-muted text-right">{notes.length} / 128</small>
                  </div>
                </div>
              </Accordion.Collapse>
            </Accordion>
          </Card>
        }

        <BottomButtonGroup
          style={{
            width: '100%',
            position: 'absolute',
            left: 0
          }}
          buttons={bottomButtons}
        />
      </Modal.Body>
    </Modal>
  );
};

ProductSelectModal.propTypes = {
  orderByWeight: PropTypes.bool.isRequired,
  product: PropTypes.object.isRequired,
  productGroup: PropTypes.object.isRequired,
  forcedModifierGroups: PropTypes.object,
  exceptionModifierGroups: PropTypes.object.isRequired,
  notesDisabled: PropTypes.bool,
  show: PropTypes.bool.isRequired,
  onAdd: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default compose(
  withCheck,
  withSettings,
)(ProductSelectModal);