import React from 'react';
import { compose } from 'recompose';
import { Col, Row } from 'react-bootstrap';

import { PAYMENT_TYPE_DISCOUNT, PAYMENT_TYPE_PLAYER_REWARD, ORDER_STATUS_CANCELLED } from '../../common/Constants';
import { HouseAdvantage, PosApi } from '../../common';
import { withCheck, withMetadata, withSettings, withToast } from '../../common/providers';
import {
  BottomButtonGroup,
  Container,
  DiscountListCard,
  Loading,
  TopNavBar,
  PaymentListCard,
} from '../../common/components';

const ApplyRewards = ({
  addGuest,
  check,
  history,
  location,
  settings,
  tenderTypes,
  toast,
  updateCheck,
}) => {
	debugger;
  const [session, setSession] = React.useState({ discounts: [] });
  const [working, setWorking] = React.useState({ show: true, text: 'Looking for discounts' });

  const title = session.sessionId ? `${session.name}'s Discounts` : 'Discounts';

  const params = new URLSearchParams(location.search);
  const guestId = params.get('id');
  const extSettings = Object.assign({}, settings, { sessionId: session.sessionId });   

  const buttons = [
    {
      name: 'Cancel',
      onClick: handleDoneOrCancel,
    },
    {
      name: 'Done',
      primary: true,
      onClick: handleDoneOrCancel,
    }
  ];

  async function handleApplyDiscount(disc) {
    setWorking({
      ...working,
      text: 'Applying discount',
      show: true,
    });

    const { discounts } = session;
    const index = discounts.indexOf(disc);

    try {
		
      const amount = disc.amount > check.balance ? check.balance : disc.amount;
      const discount = Object.assign({}, disc, { amount });	  
	  debugger;
      let extendedData = discount._data;

      // HA discounts will post to HA API then post to POS API as "Other" tender type.
      const discountTenderTypeId = tenderTypes.supported[PAYMENT_TYPE_DISCOUNT];
      if (discountTenderTypeId && discountTenderTypeId === discount.tenderTypeId) {
        await redeemDiscount(discount);
      }

      await PosApi.postPayment({
        check,
        extendedData,
        tip: 0,
        name: discount.name,
        amount: discount.amount,
        tenderTypeId: discount.tenderTypeId,
      }, settings);

      const apiCheck = await PosApi.getOrder(check.id, settings);
      updateCheck(apiCheck);

      handleApplied(index);

      toast({ success: 'Discount applied!' });

    } catch (err) {
		debugger;
      console.error(err);

      toast({ error: err.message });
      redirectToOrderSummary();
    }
  }

  async function handleDoneOrCancel() {
    setWorking({
      ...working,
      text: 'Updating order',
      show: true,
    });

    try {
      // If a redemption failed, this will release the discount
      // so that it could be queried again. If not, the disc will
      // only show up when HA timeout (HA internal) has ellapsed.
      await HouseAdvantage.endSession(extSettings);

      history.push('/pay');
    } catch (err) {
		debugger;
      console.error(err);
      toast({ error: err.message });
      redirectToOrderSummary();
    }
  }

  /**
   * Updates session discounts and show applied message to user.
   * @param {number} index The index of the discount in the array.
   */
  function handleApplied(index) {
    const newDiscounts = session.discounts.slice();
    newDiscounts[index].applied = true;

    // If balance is paid, disable all discounts
    if (!check.balance) {
      newDiscounts.forEach(d => d.disabled = true);
    }

    setSession({
      ...session,
      discounts: newDiscounts,
    });

    setWorking({
      ...working,
      show: false,
    });
  }

  /**
   * Redeems discount to the max balance (will not over tender).
   * @param {Object} discount The HA discount object.
   * @returns {Promise<Object>} The new discount object with the amount equivalent to the discount original amount or max balance.
   */
  async function redeemDiscount(discount) {
    const curDiscount = Object.assign({}, discount, { amount: check.balance });

    // Get redemption code then apply
    const { redemptionId } = await HouseAdvantage.getRedemptionId(check, curDiscount, extSettings);
    await HouseAdvantage.redeemDiscount(redemptionId, check, extSettings);

    return {
      amount: discount.amount,
      tip: 0,
      check,
      tenderTypeId: tenderTypes.supported[PAYMENT_TYPE_DISCOUNT],
    };
  }

   
  
  const redirectToOrderSummary = React.useCallback(() => {
    history.push(`/ordersummary?status=${ORDER_STATUS_CANCELLED}`);	
  }, [history]);

  React.useEffect(() => {
    const fetchPlayerRewardDiscounts = async () => {
      const playerRewardTenderTypes = tenderTypes.supported[PAYMENT_TYPE_PLAYER_REWARD];
      let discounts = [];

      for (let tenderTypeId of playerRewardTenderTypes) {
        try {

          const inquiry = {
            check,
            tenderTypeId,
            accountId: guestId,
            isLookup: false,
          };

          let apiDiscounts = await PosApi.inquiry(inquiry, settings);
          discounts = discounts.concat(apiDiscounts);

        } catch (err) {
          console.error(`Failed inquiry tender type "${tenderTypes[tenderTypeId].name}" ${tenderTypeId}`)
          console.error(err);
        }
      }

      return discounts;
    };

    const fetchDiscounts = async () => {
      try {
		  debugger;
        const tenderTypeId = tenderTypes.supported[PAYMENT_TYPE_DISCOUNT];

        if (!tenderTypeId) {
          console.warn('"Other" tender type is not configured. HA discounts will not be displayed.');
        }

        const curSession = await HouseAdvantage.startSession(settings);
        const curExtSettings = Object.assign({}, settings, { sessionId: curSession.sessionId });

        const guest = await HouseAdvantage.getPlayerInfo(guestId, null, curExtSettings);
        const haDiscounts = await HouseAdvantage.getDiscounts(guestId, tenderTypeId, check, curExtSettings);
        const playerRewardDiscounts = await fetchPlayerRewardDiscounts();

        const discounts = playerRewardDiscounts.concat(tenderTypeId ? haDiscounts : []);

        Object.assign(curSession, guest, { discounts });

        if (!check.guests.find(g => g.id === guest.id)) {
          addGuest(guest);
        }

        setSession(curSession);
        setWorking({
          ...working,
          show: false,
        });
      } catch (err) {
        console.error(err);
        //toast({ error: err.message });
        redirectToOrderSummary();
      }
    };

    fetchDiscounts();
  }, [addGuest, check, guestId, redirectToOrderSummary, settings, tenderTypes, working]);

  return (
    <Container>
      <TopNavBar title={title} buttons={[]} />

      <Loading
        icon="tag"
        text={working.text}
        show={working.show}
      />

      <Row>
        <Col md={8}>
          <DiscountListCard
            check={check}
            discounts={session.discounts}
            onApply={handleApplyDiscount}
          />
        </Col>
        <Col md={4}>
          <PaymentListCard check={check} />
        </Col>
      </Row>

      <BottomButtonGroup buttons={buttons} />
    </Container>
  );
};

export default compose(
  withCheck,
  withMetadata,
  withSettings,
  withToast,
)(ApplyRewards);