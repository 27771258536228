import React from 'react';
import PropTypes from 'prop-types';
import * as Cache from '../../LocalCache';
import * as Metadata from './Metadata';
import { withSettings } from '../SettingsProvider';

const DEFAULT_MENU_METADATA = {
  menu: {},
  productGroups: {},
  products: {},
  exceptionModifierGroups: {},
  exceptionModifiers: {},
  forcedModifierGroups: {},
  forcedModifiers: {},
  payments: {},
  terminal: {},
};
const MetadataContext = React.createContext();
const withMetadata = Component => props => (
  <MetadataContext.Consumer>
    {value => <Component {...value} {...props} />}
  </MetadataContext.Consumer>
);

const MetadataProvider = ({
  children,
  settings,
  pollingInterval, // in seconds
}) => {  
    const [isCaching, setIsCaching] = React.useState(false);
    const [pollingId, setPollingId] = React.useState(null);
    const [configMetadata, setConfigMetadata] = React.useState({
      curMenuId: Cache.getCurMenuId(),
      menus: Cache.getMenus(),
      productGroups: Cache.getProductGroups(),
      products: Cache.getProducts(),	
      exceptionModifierGroups: Cache.getExceptionModifierGroups(),
      exceptionModifiers: Cache.getExceptionModifiers(),
      forcedModifierGroups: Cache.getForcedModifierGroups(),
      forcedModifiers: Cache.getForcedModifiers(),
      tenderTypes: Cache.getTenderTypes(),
      terminal: Cache.getTerminal(),
      revCenter: Cache.getRevCenter(),
      revenueTypes: Cache.getRevenueTypes(),
    });

  const fetchMetadata = async (settings) => {
    // We are in the middle of polling and updating cache.
    // Don't do anything.
    if (isCaching) {
      return;
    }

    // Start caching
    setIsCaching(true);
    
    try {
      // TODO: refactor fetching mechanism
      setIsCaching(false);
    } catch (err) {
      console.error(err);
    }
  };

  const refreshMetadata = React.useCallback(() => {
    // If nothing has been set to temp data, prefer
    // null instead of undefined since null will parse
    // correctly.
	
    const metadata = Metadata.refreshMetadata();
    setConfigMetadata(metadata);
    return metadata;
  }, []);

  const startPolling = () => {
    const pollingId = setInterval(() => fetchMetadata(settings), pollingInterval);
    console.log(`starting polling service: ${pollingId}`);
    setPollingId(pollingId);
  };


  const stopPolling = () => {
    console.log(`stopping polling service: ${pollingId}`);
    clearInterval(pollingId);
    setPollingId(null);
  }

  const resetMetadata = React.useCallback(async () => {
    const metadata = Metadata.resetMetadata();
    setConfigMetadata(value => ({
      ...value,
      ...metadata,
    }));
  }, []);

  const metadata = Object.assign({}, configMetadata, {
    fetchMetadata,
    pollingId,
    refreshMetadata,
    resetMetadata,
    startPolling,
    stopPolling,
  });

  return (
    <MetadataContext.Provider value={metadata}>
      {children}
    </MetadataContext.Provider>
  );
};

MetadataProvider.propTypes = {
  pollingInterval: PropTypes.number.isRequired,
}

export default withSettings(MetadataProvider);
export {
  DEFAULT_MENU_METADATA,
  MetadataContext,
  withMetadata,
}