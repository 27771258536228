import React from 'react';
import PropTypes from 'prop-types';
import { PlayerRewardInquiryForm } from './rewards'
import { HaloInquiryForm } from './halo';
import { RoomChargeForm } from './pms';
import { Modal } from 'react-bootstrap';

const PaymentTypeForms = {
  PMS: RoomChargeForm,
  PlayerRewards: PlayerRewardInquiryForm,
  Other: HaloInquiryForm,
};

const PaymentModal = ({
  show,
  check,
  tenderType,
  onConfirm,
  onCancel,
}) => {
  const Form = PaymentTypeForms[tenderType.paymentType];

  return (
    <Modal show={show} backdrop="static">
      <Modal.Body className="p-4">
        <Form check={check} tenderType={tenderType} onConfirm={onConfirm} onCancel={onCancel} />
      </Modal.Body>
    </Modal>
  );
};

PaymentModal.propTypes = {
  show: PropTypes.bool.isRequired,
  check: PropTypes.object.isRequired,
  tenderType: PropTypes.object.isRequired,
};

export default PaymentModal;