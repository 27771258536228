import React from 'react';
import PropTypes from 'prop-types';
import * as PosApi from '../../common/PosApi';
import InquiryForm from '../InquiryForm';
import { compose } from 'recompose';
import { withMetadata, withToast } from '../../common/providers';

function RoomChargeForm({
  check,
  tenderType,
  onConfirm,
  onCancel,
  toast,
  terminal,
}) {
  const [results, setResults] = React.useState();

  const handleInquiry = async ({ roomNumber, firstName, lastName, tip }) => {
    try {
      const upperFirstName = firstName.toUpperCase();
      const upperLastName = lastName.toUpperCase();

      const inquiryRequest = {
        AccountId: roomNumber,
        OrderId: check.id,
        TenderTypeId: tenderType.id,
        TerminalId: terminal.id,
      };

      const accounts = await PosApi.postInquiry(inquiryRequest);

      // PosApi requires sending back the exact account obj
      // when calling subsequent POSTING in ExtendedData.
      // Save off the account in _data for each result.
      const accountResults = accounts
        .filter(acct => acct.canPost &&
          new RegExp(upperFirstName, 'i').test(acct.firstName) &&
          new RegExp(upperLastName, 'i').test(acct.lastName))
        .map(acct => ({
          tip,
          name: acct.displayName,
          amount: acct.balance,
          _data: acct,
        }));

      setResults(accountResults);
    } catch (err) {
      console.error(err);
      toast({ error: err.message });
    }
  };

  const handlePosting = async (selectedIndex, data) => {
    try {
      const account = results[selectedIndex];
      const { amount, tip } = data;

      const payment = {
        check,
        amount,
        tip,
        name: account.name,
        tenderTypeId: tenderType.id,
        extendedData: account._data,
      };

      const transaction = await PosApi.postTransaction(payment);
      onConfirm(transaction);
    } catch (err) {
      console.error(err);
      toast({ error: err.message });
    }
  };

  return <InquiryForm
    balance={tenderType.isTaxExempt ? check.taxExemptBalance : check.balance}
    results={results}
    tenderType={tenderType}
    promptRoomNumber={true}
    onInquiry={handleInquiry}
    onPosting={handlePosting}
    onCancel={onCancel}
  />;
}

RoomChargeForm.propTypes = {
  check: PropTypes.object.isRequired,
  tenderType: PropTypes.object.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default compose(
  withMetadata,
  withToast,
)(RoomChargeForm);