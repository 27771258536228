import React from 'react';
import PropTypes from 'prop-types';
import Numeral from 'numeral';
import styled from 'styled-components';
import ClampLines from 'react-clamp-lines';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  padding: .5rem .75rem;
`;

const ImageContainer = styled.div`
  display: flex;
  flex: 0 0 100px;
  width: 100px;
  height: 100px;
  margin-right: 1rem;
`;

const Image = styled.img`
  object-fit: cover;
  width: 100px;
  height: 100px;
  border-radius: 50px;
`;

const Metadata = styled.div`
  min-width: 0;
`;

const Name = styled.h4`
  color: ${props => props.theme.primaryColor};
`;

const Price = styled.div`
  color: ${props => props.theme.primaryColor};
  margin-top: .5rem;
`;

const ImageItem = ({ item }) => (
  <Container>

    {item.image &&
      <ImageContainer>
        <Image src={item.image} alt={item.name} />
      </ImageContainer>
    }

    <Metadata>
      <Name>{item.name}</Name>

      {item.description &&
        <ClampLines
          text={item.description}
          lines={3}
          id={item.id}
          buttons={false}
        />
      }

      {item.price !== undefined && item.price !== null &&
        <Price>{Numeral(item.price).format('$0,0.00')}</Price>
      }
    </Metadata>

  </Container>
);

ImageItem.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    image: PropTypes.string,
    price: PropTypes.number,
  }),
};

export default ImageItem;