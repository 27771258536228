import React from 'react';
import styled from 'styled-components';
import { ListGroup } from 'react-bootstrap';
import { compose } from 'recompose';
import { ORDER_STATUS_CANCELLED, ORDER_STATUS_ERROR, ORDER_STATUS_TIMEOUT } from '../common/Constants';
import { withMetadata, withSettings } from '../common/providers';

const Title = styled.h1`
  margin-bottom: .5rem !important;
  color: ${props => props.success ? 'var(--success)' : props.theme.primaryColor};
`;

const Promo = styled.img`
  width: 100%;
`;

const ListItem = styled(ListGroup.Item)`
  display: flex;
`;

const IconCol = styled.div`
  width: 32px;
`;

const InfoCol = styled.div`
  flex-grow: 1;
`;

const Caption = {
  success: {
    title: 'Thank You For Ordering',
    description: 'We will serve you shortly. Scan QR code to place a new order.',
  },
  [ORDER_STATUS_CANCELLED]: {
    title: 'Thank you',
    description: 'Rescan QR code to place a new order.',
  },
  [ORDER_STATUS_ERROR]: {
    title: 'See Server',
    description: 'There was an issue sending your order.  Please see server.',
  },
  [ORDER_STATUS_TIMEOUT]: {
    title: 'Session Timeout',
    description: 'Your order session has timed out. You may rescan QR code to place a new order.',
  },
}

const Thankyou = ({
  location,
  revCenter,
  resetMetadata,
  resetSettings,
}) => {
  const params = new URLSearchParams(location.search);
  const status = params.get('status') || 'success';

  const searchParams = new URLSearchParams(location.search);
  const orderNumber = searchParams.get('orderNumber');

  // If status is not supported, treat it like a cancelled (generic) message
  const caption = Caption[status] ? Caption[status] : Caption[ORDER_STATUS_CANCELLED];

  React.useEffect(() => {
    const cleanUp = async () => {
      try {
        await resetMetadata();
        resetSettings();
      } catch (err) {
        console.error(err);
      }
    };

    cleanUp();
  }, [resetMetadata, resetSettings]);

  return (
    <div className="welcome p-4 d-flex flex-column justify-content-between">
      
      <div className="text-center">
        <Title success={status === 'success'}>
          {caption.title}
        </Title>

        {orderNumber && <h4 className="text-success">Order #{orderNumber}</h4>}

        {status === 'success' &&
          <i className="fa fa-check-circle fa-2x ml-2 mt-2 mb-3 text-success" />
        }

        <p className="mb-0">{caption.description}</p>
      </div>

      {status === ORDER_STATUS_ERROR &&
        <ListGroup className="my-3">
          <ListItem>
            <IconCol>
              <i className="fa fas faw fa-building" />
            </IconCol>
            <InfoCol>
              {revCenter.name}
            </InfoCol>
          </ListItem>

          <ListItem>
            <IconCol>
              <i className="fa fas faw fa-map-marker" />
            </IconCol>
            <InfoCol>
              <div>{revCenter.address}</div>
              <div>{revCenter.city}, {revCenter.state} {revCenter.zip}</div>
            </InfoCol>
          </ListItem>

          <ListItem>
            <IconCol>
              <i className="fa fas fa-phone" />
            </IconCol>
            <InfoCol>
              <a href={`tel:${revCenter.phone}`}>
                {revCenter.phone}
              </a>
            </InfoCol>
          </ListItem>
        </ListGroup>
      }

      <div className="text-center">
        <small className="text-muted d-block pt-2">You may close this window</small>
      </div>

      {revCenter.promos.map((promo, index) =>
        <div key={index} className="text-center py-4">
          <a href={promo.url} target="_blank" rel="noopener noreferrer">
            <Promo src={promo.image} alt={promo.image} />
          </a>
        </div>
      )}
    </div>
  );
};

export default compose(
  withMetadata,
  withSettings,
)(Thankyou);