import React from 'react';
import PropTypes from 'prop-types';
import Numeral from 'numeral';
import styled from 'styled-components';
import { Card, Col, Row } from 'react-bootstrap';
import { sortItems } from '../common/helpers';

const Name = styled.h6`
  color: ${props => props.theme.primaryColor};
`;

const Modifiers = ({
  modGroupsOrderByWeight,
  product,
  forcedModifiersGroups,
  productGroup,
  exceptionModifiersGroups,
  onForcedModToggle,
  onExceptionModToggle,
}) => {

  const [selectedForcedMods, setSelectedForcedMods] = React.useState({});
  const { forcedModifiersGroups : productForcedModGroups } = product;
  const { exceptionModifierGroups: productExceptionModGroups } = productGroup;

  const handleForcedModClick = (selected, groupId, modId, mod) => {
    const group = selectedForcedMods[groupId] || {};

    const newSelectedForcedMods = {
      ...selectedForcedMods,
      [groupId]: {
        ...group,
        [modId]: true,  // The value doesn't matter, just the key
      },
    };

    if (!selected) {
      delete newSelectedForcedMods[groupId][modId];
    }

    setSelectedForcedMods(newSelectedForcedMods);

    onForcedModToggle(groupId, modId, mod);
  };

  const isForcedModGroupDisabled = (groupId) => {
    const count = selectedForcedMods[groupId] ? Object.keys(selectedForcedMods[groupId]).length : 0;
    const { min } = sortedForcedModifierGroups[groupId];

    return count >= min;
  };

  const isForcedModDisabled = (groupId, modId) => {
    const count = selectedForcedMods[groupId] ? Object.keys(selectedForcedMods[groupId]).length : 0;
    const isSelected = selectedForcedMods[groupId] && selectedForcedMods[groupId][modId];
    const { max } = sortedForcedModifierGroups[groupId];

    const isMaxed = max === count;

    return isMaxed && !isSelected;
  };

  /**
   * Sorted forced mod groups for product.
   */
  const sortedProductForcedModGroups = React.useMemo(() => {
    const modGroups = Object.keys(productForcedModGroups)
      .map(gpId => ({
        id: gpId,
        name: productForcedModGroups[gpId].name,
        position: productForcedModGroups[gpId].position,
        data: productForcedModGroups[gpId],
      }));

    return sortItems(modGroups, modGroupsOrderByWeight)
      .reduce((prev, group) => {
        return {
          ...prev,
          [group.id]: group.data,
        };
      }, {});
  }, [productForcedModGroups, modGroupsOrderByWeight]);

  /**
   * Sorted exception mod groups for product which is attached to prod group.
   */
  const sortedProductExceptionModGroups = React.useMemo(() => {
    const modGroups = Object.keys(productExceptionModGroups)
      .map(gpId => ({
        id: gpId,
        name: productExceptionModGroups[gpId].name,
        position: productExceptionModGroups[gpId].position,
        data: productExceptionModGroups[gpId],
      }));

    return sortItems(modGroups, modGroupsOrderByWeight)
      .reduce((prev, group) => {
        return {
          ...prev,
          [group.id]: group.data,
        };
      }, {});
  }, [productExceptionModGroups, modGroupsOrderByWeight]);

  /**
   * Sorted forced mod group catalog.
   */
  const sortedForcedModifierGroups = React.useMemo(() => {

    return Object.keys(forcedModifiersGroups)
      .map(fmgKey => ({
        id: fmgKey,
        name: forcedModifiersGroups[fmgKey].name,
        orderByWeight: forcedModifiersGroups[fmgKey].orderByWeight,
        modifiers: Object.keys(forcedModifiersGroups[fmgKey].forcedModifiers)
          .map(fmKey => ({
            id: fmKey,
            name: forcedModifiersGroups[fmgKey].forcedModifiers[fmKey].name,
            position: forcedModifiersGroups[fmgKey].forcedModifiers[fmKey].position,
            modifier: forcedModifiersGroups[fmgKey].forcedModifiers[fmKey],
          })),
      }))
      .reduce((prev, group) => {
        const forcedModifiers = sortItems(group.modifiers.slice(), group.orderByWeight)
          .reduce((prev, mod) => ({
            ...prev,
            [mod.id]: mod.modifier,
          }), {});

        return {
          ...prev,
          [group.id]: {
            ...forcedModifiersGroups[group.id],
            forcedModifiers,
          },
        };
      }, {});

  }, [forcedModifiersGroups]);

  /**
   * Sorted exception mod group catalog.
   */
  const sortedExceptionModGroups = React.useMemo(() => {
    return Object.keys(exceptionModifiersGroups)
      .map(emgKey => ({
        id: emgKey,
        name: exceptionModifiersGroups[emgKey].name,
        orderByWeight: exceptionModifiersGroups[emgKey].orderByWeight,
        modifiers: Object.keys(exceptionModifiersGroups[emgKey].exceptionModifier)
          .map(emKey => ({
            id: emKey,
            name: exceptionModifiersGroups[emgKey].exceptionModifier[emKey].name,
            position: exceptionModifiersGroups[emgKey].exceptionModifier[emKey].position,
            modifier: exceptionModifiersGroups[emgKey].exceptionModifier[emKey],
          })),
      }))
      .reduce((prev, group) => {
        const exceptionModifier = sortItems(group.modifiers.slice(), group.orderByWeight)
          .reduce((prev, mod) => ({
            ...prev,
            [mod.id]: mod.modifier,
          }), {});

        return {
          ...prev,
          [group.id]: {
            ...exceptionModifiersGroups[group.id],
            exceptionModifier,
          },
        };
      }, {});

  }, [exceptionModifiersGroups]);

  const getRequirementText = ( min, max ) => {
    if (min === max) {
      return `Pick ${max}`;
    }

    return `Pick ${min}-${max}`;
  }

  return (
    <div>
      {!!Object.keys(sortedProductForcedModGroups).length &&
        <div className="mb-4">
          {Object.keys(sortedProductForcedModGroups).map(fmGrpkey =>
            <div key={fmGrpkey} className="my-3">
              <div className="d-flex justify-content-start">
                <Name className="mb-0">
                  {sortedForcedModifierGroups[fmGrpkey].name}&nbsp;
                  ({getRequirementText(sortedForcedModifierGroups[fmGrpkey].min, sortedForcedModifierGroups[fmGrpkey].max)})
                </Name>

                <i className={`ml-2 fa fa-${isForcedModGroupDisabled(fmGrpkey) ? 'check-circle' : 'exclamation-triangle'} text-${isForcedModGroupDisabled(fmGrpkey) ? 'success' : 'warning'}`} />
              </div>
              <hr />

              <Row>

                {Object.keys(sortedForcedModifierGroups[fmGrpkey].forcedModifiers).map(key =>
                  <Col key={key} md={6}>
                    <Card key={key} className="border-0">
                      <p className="mb-0" style={{ fontSize: '10pt' }}>
                        <label>
                          <input
                            id={key}
                            type="checkbox"
                            name={fmGrpkey}
                            disabled={isForcedModDisabled(fmGrpkey, key)}
                            onChange={e => handleForcedModClick(
                              e.target.checked,
                              fmGrpkey,
                              key,
                              sortedForcedModifierGroups[fmGrpkey].forcedModifiers[key]
                            )}
                          />

                          <span className={`ml-2 ${isForcedModDisabled(fmGrpkey, key) ? 'text-muted' : null}`}>
                            {sortedForcedModifierGroups[fmGrpkey].forcedModifiers[key].name} ({Numeral(sortedForcedModifierGroups[fmGrpkey].forcedModifiers[key].price).format('$0,0.00')})
                          </span>
                        </label>
                      </p>
                    </Card>
                  </Col>
                )}

              </Row>
            </div>
          )}
        </div>
      }

      {!!Object.keys(sortedProductExceptionModGroups).length &&
        <>
          {Object.keys(sortedProductExceptionModGroups).map(exModGrpkey =>
            <div key={exModGrpkey} className="my-3">
              <Name className="mb-3">{sortedExceptionModGroups[exModGrpkey].name}</Name>
              <hr />

              <Row>
                {Object.keys(sortedExceptionModGroups[exModGrpkey].exceptionModifier).map(key =>
                  <Col key={key} md="6">
                    <Card className="border-0">
                      <p className="mb-0" style={{ fontSize: '10pt' }}>
                        <label>
                          <input
                            id={key}
                            type="checkbox"
                            name={exModGrpkey}
                            onChange={() => onExceptionModToggle(exModGrpkey, key, exceptionModifiersGroups[exModGrpkey].exceptionModifier[key])}
                          />
                          &nbsp;
                          {exceptionModifiersGroups[exModGrpkey].exceptionModifier[key].name} ({Numeral(exceptionModifiersGroups[exModGrpkey].exceptionModifier[key].price).format('$0,0.00')})
                        </label>
                      </p>
                    </Card>
                  </Col>
                )}
              </Row>
            </div>
          )}
        </>
      }
    </div>
  );
};

Modifiers.propTypes = {
  modGroupsOrderByWeight: PropTypes.bool.isRequired,
  forceModifiersGroup: PropTypes.shape({
    name: PropTypes.string.isRequired,
    forcedModifiers: PropTypes.object.isRequired,
  }),
  onForcedModToggle: PropTypes.func.isRequired,
  onExceptionModToggle: PropTypes.func.isRequired,
};

export default Modifiers;