import React from 'react';

const Unauthorized = () => {
  return (
    <div className="h-100 d-flex justify-content-center align-items-center">
      <div className="text-muted text-center">
        <h3 className="text-muted">Unauthorized</h3>
        <p>Your session has expired. See server.</p>

        <small>You may close this window</small>

      </div>
    </div>
  );
};

export default Unauthorized;