import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup } from 'react-bootstrap';
import { ThemedButton } from '../Themed';

const BottomButtonGroup = ({
  buttons,
  ...rest
}) => {
  const renderButton = (btn, index) => {
    const CurButton = btn.primary ? ThemedButton : Button;
    return (
      <CurButton
        key={index}
        variant="dark"
        disabled={btn.disabled}
        onClick={btn.onClick}
      >
        {btn.name || btn.content}
      </CurButton>
    );
  };

  return (
    <div>
      <ButtonGroup
        size="lg"
        className="action-bar-container bg-light"
        style={{ zIndex: 2 }}
        {...rest}
      >

        {buttons.map((btn, index) => renderButton(btn, index))}

      </ButtonGroup>
    </div>
  );
};

BottomButtonGroup.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      content: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
      ]),
      onClick: PropTypes.func.isRequired,
      primary: PropTypes.bool,
      disabled: PropTypes.bool,
    })
  ),
};

export default BottomButtonGroup;