import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import Spinner from './Spinner';

const styles = {
  imageRoot: {
    position: 'relative',
  },
  imageIcon: {
    position: 'absolute',
    left: '47%',
    top: '42%',
  },
};

const Loading = ({
  complete,
  icon,
  show,
  text = 'Loading',
}) => (
  <Modal
    show={show}
    centered={true}
    backdrop="static"
    size="sm"
  >
    <Modal.Body className="p-4 text-center">
      <div className="p-2">
        <div style={styles.imageRoot}>
          <Spinner complete={complete} />
          {icon && <i className={`fa fa-${icon}`} style={styles.imageIcon} />}
        </div>
        <h4 className="mt-3 font-weight-bolder">{text}</h4>
      </div>
    </Modal.Body>
  </Modal> 
);

Loading.propTypes = {
  complete: PropTypes.bool,
  icon: PropTypes.string,
  show: PropTypes.bool.isRequired,
  text: PropTypes.string,
};

export default Loading;