import React from 'react';
import { compose } from 'recompose';
import ConnectStartOrder from './ConnectStartOrder';
import { withMetadata, withSettings } from '../common/providers';
import * as Metadata from '../common/providers/MetadataProvider/Metadata';
import { LocalCache } from '../common';
import { PAYMENT_TYPE_CC } from '../common/Constants';

const SettingsDefault = {
  token: '',
  primaryColor: "#C0412D",
  revCenterName: '',
};

const Connect = ({
  location,
  history,
  tenderTypes,
  setSettings,
  refreshMetadata,
  curMenuId,
  revCenter,
}) => {
  const [token, setToken] = React.useState();
  const [isInitialized, setInitialized] = React.useState(false);
  const supportedTenderTypes = tenderTypes?.supported || {};
  const canOrder = supportedTenderTypes[PAYMENT_TYPE_CC];

  if (isInitialized && !canOrder) {
    console.warn(`Missing supported CC tender type ${PAYMENT_TYPE_CC}`);
  }

  const params = new URLSearchParams(location.search);
  const key = params.get('key');

  React.useEffect(() => {
    const validateKey = async () => {
      try {
        const { token } = await Metadata.verifyKey(key);
        setToken(token);

        if (!token) {
          setInitialized(true);
        }
      } catch (err) {
        console.error(err);
        history.push('/error');
      }
    };

    validateKey();
  }, [key, history, setSettings]);

  React.useEffect(() => {
    const fetchMetadata = async () => {
      try {
        const settings = {
          ...SettingsDefault,
          token,
        };

        LocalCache.setSettings(settings);
        const meta = await Metadata.getTerminalAuthLambda('', '', '', 'load');

        // Set meta in temp keys
        await Metadata.setTempMetadata(meta);

        // Save token to settings
        setSettings(settings);

        refreshMetadata();

      } catch(err) {
        console.error(err);

        // Reset settings
        setSettings({ ...SettingsDefault });

        history.push('/error');
      }

      setInitialized(true);
    } 

    if (token) {
      fetchMetadata();
    }
  }, [token, history, refreshMetadata, setSettings]);

  return <ConnectStartOrder
    isInitialized={isInitialized}
    canOrder={canOrder}
    isClosed={!curMenuId}
    revCenter={revCenter}
  />;
};

export default compose(
  withMetadata,
  withSettings,
)(Connect);