import React from 'react';
import PropTypes from 'prop-types';

import NavButtonGroup from './NavButtonGroup';

const TopNavBar = ({
  title,
  buttons = [],
}) => (
  <div className="d-flex justify-content-between align-items-center">
    <h2 className="mb-0 font-weight-bolder">{title}</h2>
    <div>
      <NavButtonGroup buttons={buttons} />
    </div>
  </div>
);

TopNavBar.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      primary: PropTypes.bool,
      onClick: PropTypes.func,
    })
  ),
}

export default TopNavBar;