import React from 'react';
import pkg from '../../package.json';
import PaymentMethods from './PaymentMethods';
import qs from 'query-string';
import PaymentModal from './PaymentModal';
import { useIdleTimer } from 'react-idle-timer';
import { Redirect } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import TipPrompt from './TipPrompt';
import { compose } from 'recompose';
import * as PosApi from '../common/PosApi';
import * as fcc from '../common/fcc';
import { withCheck, withMetadata, withSettings, withToast } from '../common/providers';
import { PAYMENT_TYPE_CC, PAYMENT_TYPE_DISCOUNT, ORDER_STATUS_CANCELLED, ORDER_STATUS_ERROR, ORDER_STATUS_TIMEOUT, TEXT_CANCEL } from '../common/Constants';
import { RewardPrompt } from './rewards';
import { BottomButtonGroup, ConfirmCancelModal, Container, ItemizedCard, Loading, PaymentListCard, TopNavBar } from '../common/components';

const Pay = ({
  check,
  history,
  settings,
  toast,
  tenderTypes,
  terminal,
  location,
  updateCheck,
}) => {
  const [paymentType, setPaymentType] = React.useState();
  const [showCancel, setShowCancel] = React.useState(false);
  const [showRewardPrompt, setShowRewardPrompt] = React.useState(false);
  const [showTip, setShowTip] = React.useState(false);
  const [working, setWorking] = React.useState({ text: 'Updating Order', icon: 'list', show: !check.sent });
  const [transCount, setTransCount] = React.useState(check.payments.length);
  const [inquiryTenderTypeId, setInquiryTenderTypeId] = React.useState();
  const discountTenderTypeId = tenderTypes.supported[PAYMENT_TYPE_DISCOUNT];
  const ccTenderTypeId = tenderTypes.supported[PAYMENT_TYPE_CC];

  // Change when toggling between kiosk and qr
  const qrMode = true;

  const params = new URLSearchParams(location.search);
  const redirectTo = params.get('redirectTo');

  const handleIdle = async () => {
    /*
    setShowTip(false);
    await handleCancel(ORDER_STATUS_TIMEOUT);
    */
  };

  const { reset: resetTimer, pause: pauseTimer } = useIdleTimer({
    timeout: settings.payTimeout || 180000,
    onIdle: handleIdle,
  });

  const handleCancel = async (status = ORDER_STATUS_CANCELLED) => {
    pauseTimer();

    setShowCancel(false);

    setWorking({
      ...working,
      text: 'Cancelling order',
      show: true,
    });

    try {
      await PosApi.voidOrder(check.id);
    } catch (err) {
      console.error(err);
    }

    redirectToThankYou({ status });
  };

  const handleContinue = () => {
    if (check.balance) {
      setShowTip(true);
    } else {
      redirectToThankYou({ orderNumber: check.number });
    }
  };

  const handleCreditCardPayment = async ({ amount, tip, payment }) => {
    pauseTimer();
    setShowTip(false);

    const { sessionKey, paymentKey, cardNumber, issuer } = payment || {};

    const loadingText = qrMode ? `Applying Payment` : 'Waiting For Pinpad';

    setWorking({
      ...working,
      icon: 'credit-card',
      text: loadingText,
      show: true,
    });

    // Send the partial amount as total
    const curOrder = {
      ...check,
      total: amount,
    };

    try {
      const request = {
        attempt: 1,
        paymentKey,
        sessionKey,
        tip,
        order: curOrder,
        version: pkg.version,
        userId: terminal._data.DefaultUserId,
      };

      const response = await fcc.pay(request);

      await postTransactionAndFinalize(response, amount, tip, { cardNumber, issuer });

    } catch (err) {
      console.error(err);

      setWorking({
        ...working,
        show: false,
      });

      toast({ error: err.message });
      setShowTip(true);

      resetTimer();
    }
  };

  const handleHideCancel = () => {
    resetTimer();
    setShowCancel(false);
  };

  const handlePaymentMethodSelect = (paymentType) => {
    setPaymentType(paymentType);
    setShowTip(!!paymentType);
  };

  const handlePosting = async (transaction) => {
    setInquiryTenderTypeId();
    setTransCount(transCount + 1);
  };

  const handleShowCancel = () => {
    pauseTimer();
    setShowCancel(true);
  };

  const postTransactionAndFinalize = async (response, amount, tip, cardInfo) => {
    try {
      await PosApi.postCreditCardPayment(check, ccTenderTypeId, response, amount, tip, cardInfo);
      setTransCount(transCount + 1);
      setWorking({
        ...working,
        show: false,
      });
    } catch (err) {
      console.error(err);

      toast({ error: err.message });
      redirectToThankYou({ status: ORDER_STATUS_ERROR });
    }
  };

  const redirectToDiscounts = (guestId) => history.push(`/pay/rewards?id=${guestId}`);

  const redirectToThankYou = React.useCallback((query) => {
    const queryStringValue = qs.stringify(query);
    history.push(`/thankyou?${queryStringValue}`);
  }, [history]);

  const handleAddPlayerCard = (swipeData) => {
    const guestId = swipeData.id;
    if (!guestId) {
      toast({ error: 'Bad swipe, try again.' });
      return;
    }

    redirectToDiscounts(guestId);
  };

  const finalizeOrder = React.useCallback(async (updatedCheck) => {
    try {
      setWorking(value => ({
        ...value,
        icon: 'th',
        text: 'Finalizing Order',
        show: true,
      }));

      await PosApi.finalizeOrder(updatedCheck);
      redirectToThankYou({ orderNumber: updatedCheck.number });
    } catch (err) {
      console.error(err);

      setWorking(value => ({
        ...value,
        show: false,
      }));

      toast({ error: err.message });
      redirectToThankYou({ status: ORDER_STATUS_ERROR });
    }
  }, [redirectToThankYou, toast, setWorking]);

  React.useEffect(() => {
    const finalize = async () => await finalizeOrder(check);

    // Finalize order if balance is paid
    if (!check.balance) {
      finalize();
    }
  }, [check, finalizeOrder]);

  React.useEffect(() => {
    const fetchOrder = async () => {
      try {
        const apiOrder = await PosApi.getOrder(check.id);
        updateCheck(apiOrder);
      } catch (err) {
        console.error(err);
        toast({ error: err.message || 'Error occurred while getting your updated order. Try again.' });
      }
    };

    fetchOrder();
  }, [check.id, updateCheck, toast, transCount, finalizeOrder]);

  const topButtons = [];
  if (discountTenderTypeId && !qrMode) {
    topButtons.push({
      name: 'Add Loyalty Card',
      icon: 'id-card',
      disabled: !check.balance,
      primary: true,
      onClick: () => setShowRewardPrompt(true),
    });
  }

  const bottomButtons = [
    {
      name: 'Cancel',
      onClick: handleShowCancel,
    },
  ];

  if (!qrMode) {
    bottomButtons.push({
      name: qrMode ? 'Pay' : 'Continue',
      primary: true,
      disabled: !ccTenderTypeId,
      onClick: handleContinue,
    });
  }

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <Container style={{ paddingBottom: 100 }}>
      <TopNavBar title="Pay" buttons={topButtons} />

      {!qrMode &&
        <RewardPrompt
          show={showRewardPrompt}
          onSwipe={handleAddPlayerCard}
          onCancel={() => setShowRewardPrompt(false)}
        />
      }

      <Loading {...working} />

      {check.sent && (
        <>
          <Row className="my-3">
            <Col lg={8}>
              <div className="mb-3">
                <ItemizedCard items={check.items} />
              </div>
            </Col>
            <Col lg={4}>
              <PaymentListCard check={check} />
            </Col>
          </Row>

          {qrMode &&
            <>
              <h6 className="text-uppercase">Payment Method</h6>
              <PaymentMethods
                tenderTypes={tenderTypes}
                onSelect={handlePaymentMethodSelect}
                onInquirySelect={tenderTypeId => setInquiryTenderTypeId(tenderTypeId)}
              />
            </>
          }
        </>
      )}

      {inquiryTenderTypeId &&
        <PaymentModal
          show={!!inquiryTenderTypeId}
          check={check}
          tenderType={tenderTypes[inquiryTenderTypeId]}
          onConfirm={handlePosting}
          onCancel={() => setInquiryTenderTypeId()}
        />
      }

      {showTip &&
        <TipPrompt
          checkAmount={check.balance}
          show={showTip}
          qrMode={qrMode}
          paymentType={paymentType}
          onCancel={() => setShowTip(false)}
          onConfirm={handleCreditCardPayment}
        />
      }

      <ConfirmCancelModal
        message={TEXT_CANCEL}
        show={showCancel}
        onCancel={handleHideCancel}
        onConfirm={handleCancel}
      />

      <BottomButtonGroup buttons={bottomButtons} />

    </Container>
  );
};

export default compose(
  withCheck,
  withMetadata,
  withSettings,
  withToast,
)(Pay);