import React from 'react';
import hash from 'object-hash';
import * as LocalCache from '../../LocalCache';
import { compose } from 'recompose';
import { withMetadata } from '../MetadataProvider';
import { withSettings } from '../SettingsProvider';

const DEFAULT_CHECK = {
  items: {},
  payments: [],
  guests: [],
  number: '',
  subtotal: 0,
  totalQuantity: 0,
  total: 0,
  tax: 0,
  balance: 0,
  sent: false,
  isClosed: false,
};

const CheckContext = React.createContext();

const CheckProvider = ({
  children,
}) => {
  const [check, setCheck] = React.useState(LocalCache.getCheck() || DEFAULT_CHECK);

  /**
   * @typedef CheckItem Item added to the check.
   * @property {string} id The identifier for the item.
   * @property {string} name The name of the item.
   * @property {number} price The price of the item before tax.
   * @property {number} quantity The quantity of the item as integer.
   */

  /**
   * Add an item to the check.
   * @param {CheckItem} item Item to add to check.
   */
  const addItem = (item) => {
    if (!item.id || !item.name) {
      throw new Error('Check item must have id, name, price, and quatity.');
    }

    const itemHash = hash(item);
    const newItems = { ...check.items };

    const newItem = newItems[itemHash] || { ...item, quantity: 1 };

    if (newItems[itemHash]) {
      newItem.price += item.price;
      newItem.quantity++;
    }

    newItems[itemHash] = newItem;

    const newCheck = {
      ...cloneCheck(check),
      items: newItems,
      subtotal: check.subtotal + newItem.price * newItem.quantity,
      totalQuantity: check.totalQuantity + 1,
    };

    LocalCache.setCheck(newCheck);

    setCheck(newCheck);
  };

  /**
   * @typedef Guest Guest object
   * @property {string} id The identifier of the guest (ex: card number, user id, etc).
   * @property {string} name The display name of the guest.
   * @property {string} firstName The first name fo the guest.
   * @property {string} lastName The last name of the guest.
   * @property {string} tierId The identifier of the tier level.
   * @property {string} tierName The name of the tier.
   */

  /**
   * 
   * @param {Guest} guest Guest object.
   */
  const addGuest = (guest) => {
    if (!guest.id || !guest.name) {
      throw new Error('account.id and account.name must be provided.');
    }

    const newCheck = cloneCheck(check);
    newCheck.guests.push(guest);

    LocalCache.setCheck(newCheck);

    setCheck(newCheck);
  };

  const createNewCheck = () => cloneCheck(DEFAULT_CHECK);

  /**
   * Remove item from check.
   * @param {number} index Index of the item.
   */
  const removeItem = (hashId) => {
    const newCheck = cloneCheck(check);
    const item = newCheck.items[hashId];

    if (item) {
      newCheck.subtotal -= item.price;
      newCheck.totalQuantity -= item.quantity;

      delete newCheck.items[hashId];

      LocalCache.setCheck(newCheck);

      setCheck(newCheck);
    }
  }

  /**
   * Update/Merge check object.
   * @param {Object} newCheck New check to merge.
   */
  const updateCheck = React.useCallback((newCheck) => {

    setCheck(value => {
      const updatedCheck = {
        ...value,
        ...newCheck,
      };

      LocalCache.setCheck(updatedCheck);

      return updatedCheck;
    });
  }, []);

  /**
   * Update quantity for the check item.
   * @param {number} index Index of the item.
   * @param {number} quantity Update item to this quantity integer value.
   * @param {number} menuPrice The menu price for this item.
   */
  const updateQuantity = (hashId, quantity, menuPrice) => {
    const newCheck = cloneCheck(check);
    const item = newCheck.items[hashId];

    const exceptionModsTotal = getModGroupsTotal(item.exceptionMods);
    const forcedModsTotal = getModGroupsTotal(item.forcedMods);
    const price = menuPrice + exceptionModsTotal + forcedModsTotal;

    // Subtract old values from totals
    newCheck.subtotal -= item.price;
    newCheck.totalQuantity -= item.quantity;

    // Update item quantity and price
    item.quantity = quantity;
    item.price = quantity * price;

    // Update totals
    newCheck.subtotal += item.price;
    newCheck.totalQuantity += item.quantity;

    LocalCache.setCheck(newCheck);

    setCheck(newCheck);
  };

  /**
   * Deep copy the current check.
   */
  const cloneCheck = (chk) => JSON.parse(JSON.stringify(chk));

  const obj = {
    check,
    addItem,
    addGuest,
    createNewCheck,
    removeItem,
    updateCheck,
    updateQuantity,
  };

  return (
    <CheckContext.Provider value={obj}>
      {children}
    </CheckContext.Provider>
  );
};

function getModGroupsTotal(modGroups) {
  return Object.keys(modGroups)
    .reduce((total, groupId) => {

      // Get total of all mods for the group
      const groupTotal = Object.keys(modGroups[groupId])
        .reduce((modsTotal, modId) => {
          const price = modGroups[groupId][modId].price;
          return modsTotal + price;
        }, 0);

      // Running total of all mods
      return total + groupTotal;
    }, 0);
}

const withCheck = Component => props => (
  <CheckContext.Consumer>
    {value => <Component {...props} {...value} />}
  </CheckContext.Consumer>
);

export default compose(
  withMetadata,
  withSettings,
)(CheckProvider);

export {
  CheckContext,
  withCheck,
};