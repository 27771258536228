import {
  CHECK_KEY,
  CUR_MENU_ID,
  MENUS_KEY,
  PRODUCT_GROUPS_KEY,
  PRODUCTS_KEY,
  EXCEPTION_MODIFIERS_GROUPS_KEY,
  EXCEPTION_MODIFIERS_KEY,
  FORCED_MODIFIER_GROUPS_KEY,
  FORCED_MODIFIERS_KEY,
  REV_CENTER_KEY,
  TENDER_TYPES_KEY,
  TERMINAL_KEY,
  SETTINGS_KEY,
  POLLING_KEY,
  REVENUE_TYPES_KEY,
} from './Constants';

export const Keys = {
  CHECK_KEY,
  CUR_MENU_ID,
  MENUS_KEY,
  PRODUCT_GROUPS_KEY,
  PRODUCTS_KEY,
  EXCEPTION_MODIFIERS_GROUPS_KEY,
  EXCEPTION_MODIFIERS_KEY,
  FORCED_MODIFIER_GROUPS_KEY,
  FORCED_MODIFIERS_KEY,
  REV_CENTER_KEY,
  TENDER_TYPES_KEY,
  TERMINAL_KEY,
  SETTINGS_KEY,
  POLLING_KEY,
  REVENUE_TYPES_KEY,
};

const TEMP_SUFFIX = '_temp';

/**
 * Clear all cached values.
 */
export const clear = () => localStorage.clear();

export const clearExceptRevCenter = () => Object.keys(Keys).forEach(key => {
  const curKey = Keys[key];
  const tempKey = `${curKey}${TEMP_SUFFIX}`;

  // Remove all temp data
  localStorage.removeItem(tempKey);

  // Do not remove rev center to show assets during error pages
  if (curKey !== Keys.REV_CENTER_KEY) {
    localStorage.removeItem(Keys[key]);
  }
});

/**
 * Get current check from cache.
 */
export const getCheck = () => getItem(CHECK_KEY);

/**
 * Get current menu id.
 */
export const getCurMenuId = () => getItem(CUR_MENU_ID);

/**
 * Get menus from local storage.
 * @returns {Object} Menus object.
 */
export const getMenus = () => getItem(MENUS_KEY);

/**
 * Get product groups from local storage.
 * @returns {Object} Product groups object.
 */
export const getProductGroups = () => getItem(PRODUCT_GROUPS_KEY);

/**
 * Get products from local storage.
 * @returns {Object} Products object.
 */
export const getProducts = () => getItem(PRODUCTS_KEY);


/**
 * Get revenue center from local storage.
 * @returns {Object} Revenue center object.
 */
export const getRevCenter = () => getItem(REV_CENTER_KEY);

/**
 * Get revenue types from local storage.
 * @returns {Object} Revenue types object.
 */
export const getRevenueTypes = () => getItem(REVENUE_TYPES_KEY);

/**
 * Get settings from local storage.
 * @returns {Object} Settings object.
 */
export const getSettings = () => getItem(SETTINGS_KEY);

/**
 * Get tender types from local storage.
 * @returns {Object} Tender types object.
 */
export const getTenderTypes = () => getItem(TENDER_TYPES_KEY);

/**
 * Get new menus from temp storage.
 * @returns {Object} New menus object.
 */
export const getTempMenus = () => getTempItem(MENUS_KEY);

/**
 * Get new product groups from temp storage.
 * @returns {Object} New product groups object.
 */
export const getTempProductGroups = () => getTempItem(PRODUCT_GROUPS_KEY);

/**
 * Get new products from temp storage.
 * @returns {Object} New products object.
 */
export const getTempProducts = () => getTempItem(PRODUCTS_KEY);


/**
 * Get new revenue center data from temp storage.
 * @returns {Object} New revenue center object.
 */
export const getTempRevCenter = () => getTempItem(REV_CENTER_KEY);

/**
 * Get new revenue types data from temp storage.
 * @returns {Object} New revenue types object.
 */
export const getTempRevenueTypes = () => getTempItem(REVENUE_TYPES_KEY);

/**
 * Get new tender types data from temp storage.
 * @returns {Object} New tender types object.
 */
export const getTempTenderTypes = () => getTempItem(TENDER_TYPES_KEY);

/**
 * Get new terminal data from temp storage.
 * @returns {Object} New terminal object.
 */
export const getTempTerminal = () => getTempItem(TERMINAL_KEY);

/**
 * Get terminal from local storage.
 * @returns {Object} Terminal object.
 */
export const getTerminal = () => getItem(TERMINAL_KEY);

/**
 * Set current check.
 * @param {object} check Check object.
 */
export const setCheck = (check) => setItem(CHECK_KEY, check);

/**
 * Set current menu id.
 * @param {string} menuId Current menu id.
 */
export const setCurMenuId = (menuId) => setItem(CUR_MENU_ID, menuId);

/**
 * Set menus to local storage.
 * @param {Object} menus Menus as object.
 */
export const setMenus = (menus) => setItem(MENUS_KEY, menus);

/**
 * Set product groups to local storage.
 * @param {Object} productGroups ProductGroups as object.
 */
export const setProductGroups = (productGroups) => setItem(PRODUCT_GROUPS_KEY, productGroups);

/**
 * Set products to local storage.
 * @param {Object} products Products as object.
 */
export const setProducts = (products) => setItem(PRODUCTS_KEY, products);

/**
 * Set revenue center to local storage.
 * @param {Object} revCenter Revenue center object.
 */
export const setRevCenter = (revCenter) => setItem(REV_CENTER_KEY, revCenter);

/**
 * Set revenue types to local storage.
 * @param {Object} revenueTypes Revenue types object.
 */
export const setRevenueTypes = (revenueTypes) => setItem(REVENUE_TYPES_KEY, revenueTypes);

/**
 * Set settings to local storage.
 * @param {Object} Settings object.
 */
export const setSettings = (obj) => setItem(SETTINGS_KEY, obj);

export const setPolling = (obj) => setItem(POLLING_KEY, obj);

/**
 * Set tender types to local storage.
 * @param {Object} tenderTypes Payments as object.
 */
export const setTenderTypes = (tenderTypes) => setItem(TENDER_TYPES_KEY, tenderTypes);

/**
 * Set new menus data to temp storage.
 * @param {Object} menus Menus as object.
 */
export const setTempMenus = (menus) => setTempItem(MENUS_KEY, menus);

/**
 * Set new revenue center data to temp storage.
 * @param {Object} revCenter Revenue center object.
 */
export const setTempRevCenter = (revCenter) => setTempItem(REV_CENTER_KEY, revCenter);

/**
 * Set new revenue types data to temp storage.
 * @param {Object} revenueTypes Revenue types object.
 */
export const setTempRevenueTypes = (revenueTypes) => setTempItem(REVENUE_TYPES_KEY, revenueTypes);

/**
 * Set new product groups data to temp storage.
 * @param {Object} productGroups ProductGroups as object.
 */
export const setTempProductGroups = (productGroups) => setTempItem(PRODUCT_GROUPS_KEY, productGroups);

/**
 * Set new products data to temp storage.
 * @param {Object} products Products as object.
 */
export const setTempProducts = (products) => setTempItem(PRODUCTS_KEY, products);

/**
 * Set new tender types data to temp storage.
 * @param {Object} tenderTypes Payments as object. 
 */
export const setTempTenderTypes = (tenderTypes) => setTempItem(TENDER_TYPES_KEY, tenderTypes);

/**
 * Set new terminal data to temp storage.
 * @param {Object} terminal Terminal object.
 */
export const setTempTerminal = (terminal) => setTempItem(TERMINAL_KEY, terminal);

/**
 * Set terminal data to local storage.
 * @param {Object} terminal Terminal object.
 */
export const setTerminal = (terminal) => setItem(TERMINAL_KEY, terminal);


/* -- Inoday Development START -- */

/* ------------ ForcedModifiers ------------- */
/**
 * Get Forced Modifier Groups from local storage.
 * @returns {Object} Forced Modifier Group object.
 */
export const getForcedModifierGroups = () => getItem(FORCED_MODIFIER_GROUPS_KEY);

/**
 * Get new Forced modifier groups from temp storage.
 * @returns {Object} New Forced Modifier Groups object.
 */
export const getTempForcedModifierGroups = () => getTempItem(FORCED_MODIFIER_GROUPS_KEY);

/**
 * Set Forced Modifier Groups to local storage.
 * @param {Object} forcedModifierGroups ForcedModifierGroups as object.
 */
export const setForcedModifierGroups = (forcedModifierGroups) => setItem(FORCED_MODIFIER_GROUPS_KEY, forcedModifierGroups);

/**
 * Set new Forced Modifier Groups data to temp storage.
 * @param {Object} forcedModifierGroups ForcedModifierGroups as object.
 */
export const setTempForcedModifierGroups = (forcedModifierGroups) => setTempItem(FORCED_MODIFIER_GROUPS_KEY, forcedModifierGroups);


/**
 * Get product groups from local storage.
 * @returns {Object} Product groups object.
 */
export const getForcedModifiers = () => getItem(FORCED_MODIFIERS_KEY);

/**
 * Get new products from temp storage.
 * @returns {Object} New products object.
 */
export const getTempForcedModifiers = () => getTempItem(FORCED_MODIFIERS_KEY);

/**
 * Set ForcedModifiers to local storage.
 * @param {Object} products forcedModifiers as object.
 */
export const setForcedModifiers = (forcedModifiers) => setItem(FORCED_MODIFIERS_KEY, forcedModifiers);

/**
 * Set new ForcedModifiers data to temp storage.
 * @param {Object} forcedModifiers as object.
 */
export const setTempForcedModifiers = (forcedModifiers) => setTempItem(FORCED_MODIFIERS_KEY, forcedModifiers);


/* ------------ ExceptionModifierGroup ------------- */
/**
 * Get Exception Modifier Groups from local storage.
 * @returns {Object} Exception Modifier Group object.
 */
export const getExceptionModifierGroups = () => getItem(EXCEPTION_MODIFIERS_GROUPS_KEY);

/**
 * Get new exception modifier groups from temp storage.
 * @returns {Object} New Exception Modifier Groups object.
 */
export const getTempExceptionModifierGroups = () => getTempItem(EXCEPTION_MODIFIERS_GROUPS_KEY);

/**
 * Set Exception Modifier Groups to local storage.
 * @param {Object} exceptionModifierGroups ExceptionModifierGroups as object.
 */
export const setExceptionModifierGroups = (exceptionModifierGroups) => setItem(EXCEPTION_MODIFIERS_GROUPS_KEY, exceptionModifierGroups);

/**
 * Set new Exception Modifier Groups data to temp storage.
 * @param {Object} exceptionModifierGroups ExceptionModifierGroups as object.
 */
export const setTempExceptionModifierGroups = (exceptionModifierGroups) => setTempItem(EXCEPTION_MODIFIERS_GROUPS_KEY, exceptionModifierGroups);


/* ------------ ExceptionModifiers ------------- */
/**
 * Get ExceptionModifiers from local storage.
 * @returns {Object} ExceptionModifiers object.
 */
export const getExceptionModifiers = () => getItem(EXCEPTION_MODIFIERS_KEY);

/**
 * Get new exceptionModifiers from temp storage.
 * @returns {Object} New ExceptionModifiers object.
 */
export const getTempExceptionModifiers = () => getTempItem(EXCEPTION_MODIFIERS_KEY);

/**
 * Set Exception Modifier Groups to local storage.
 * @param {Object} exceptionModifiers ExceptionModifiers as object.
 */
export const setExceptionModifiers = (exceptionModifiers) => setItem(EXCEPTION_MODIFIERS_KEY, exceptionModifiers);

/**
 * Set new Exception Modifiers data to temp storage.
 * @param {Object} exceptionModifiers ExceptionModifiers as object.
 */
export const setTempExceptionModifiers = (exceptionModifiers) => setTempItem(EXCEPTION_MODIFIERS_KEY, exceptionModifiers);

export const getpollingtime = () => getItem(POLLING_KEY);

/* -- Inoday Development END -- */


/* -- Helpers -- */
/**
 * Get object from local storage.
 * @param {string} key Storage key.
 */
const getItem = (key) => JSON.parse(localStorage.getItem(key));

/**
 * Get temp object from local storage.
 * @param {string} key The destination key where the temp item needs to go (ex: menus);
 */
const getTempItem = (key) => getItem(`${key}${TEMP_SUFFIX}`);

/**
 * 
 * @param {string} key The destination key where the temp item needs to go (ex: menus).
 * @param {*} item The object of the temp item to store in local storage.
 */
const setTempItem = (key, item) => setItem(`${key}${TEMP_SUFFIX}`, item);

/**
 * Store item in local storage.
 * @param {string} key The local storage key.
 * @param {*} item The object to store in local storage.
 */
const setItem = (key, item) => localStorage.setItem(key, JSON.stringify(item));

