import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { Button, Collapse, ListGroup } from 'react-bootstrap';
import { withSettings } from '../providers';

const NameContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

const SelectedName = styled.h2`
  margin-bottom: 0;
  font-weight: bolder;

  @media only screen and (max-width: 600px){
    font-size: 1.3rem;
  }
`;

const Option = styled.h4`
  font-weight: bolder;
  margin: 0;
  color: ${props => props.selected ? props.theme.primaryColor : '#000'};
`;

const MenuFilter = ({
  history,
  options,
  selectedId,
}) => {
  const [show, setShow] = React.useState(false);
  const selected = options.find(opt => opt.id === selectedId);

  const handleClick = (option) => {
    setShow(false);
    history.push(option.link);
  };
  
  return (
    <>
      <NameContainer onClick={() => setShow(!show)}>
        <SelectedName>{selected.name}</SelectedName>
        <Button
          variant="link"
          className="ml-3 mb-0 p-0 rounded-circle font-weight-bolder text-dark h3"
        >
          <i className={`fa 2x fa-chevron-${show ? 'up' : 'down'}`} />
        </Button>
      </NameContainer>

      <Collapse in={show}>
        <div>

          <ListGroup className="shadow-sm mb-4">
            {options.map(opt =>
              <ListGroup.Item
                action
                key={opt.id}
                className="p-3 text-dark text-decoration-none"
                onClick={() => handleClick(opt)}
              >
                <Option selected={selectedId === opt.id}>{opt.name}</Option>
              </ListGroup.Item>
            )}
          </ListGroup>

        </div>
      </Collapse>
    </>
  );
};

MenuFilter.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    })
  ),
  selectedId: PropTypes.string.isRequired,
};

export default compose(
  withRouter,
  withSettings,
)(MenuFilter);