import React from 'react';
import styled from 'styled-components';
import { Nav, Navbar } from 'react-bootstrap';
import { KioskLogo } from '../images';
import { withMetadata } from '../providers';

const Image = styled.img`
  height: 50px;
`;

const MenuTopNav = ({
  revCenter,
}) => (
  <Navbar className="shadow-sm bg-white" fixed="top">
    <Navbar.Brand>
      <Image src={revCenter?.logoImageUrl || KioskLogo} alt={revCenter?.name} />
    </Navbar.Brand>

    <Nav className="ml-auto">
      <Nav.Link target="_blank" href="https://www.boydgaming.com/mobile-ordering-faq">
        <i className="fa fas fa-info-circle" />
      </Nav.Link>
    </Nav>
  </Navbar>
);

export default withMetadata(MenuTopNav);