import React from 'react';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import { Card, Col, Row } from 'react-bootstrap';
import db from '../common/providers/MetadataProvider/metadataDb';
import { ImageItem } from '../common/components';

const Products = ({
  orderByWeight,
  productGroup,
  onSelect,
}) => {		
  const [loading, setLoading] = React.useState(true);
  const [products, setProducts] = React.useState();
  const { id: productGroupId } = productGroup;

  React.useEffect(() => {
    const getProductsFromDb = async () => {
      let sortFields = ['name'];
      let sortMethods = ['asc'];

      if (orderByWeight) {
        sortFields = ['position'].concat(sortFields);
        sortMethods = ['desc'].concat(sortMethods);
      }

      const products = await db.products
        .where('productGroupIds')
        .equals(productGroupId)
        .toArray();

      const sorted = _.orderBy(products, sortFields, sortMethods);

      const catalog = sorted
        .reduce((catalog, prod) => {
          return {
            ...catalog,
            [prod.id]: {
              ...prod,
            },
          };
        }, {});

      setProducts(catalog);
      setLoading(false);
    };

    getProductsFromDb();
  }, [productGroupId, orderByWeight]);

  if (loading) {
    return null;
  }

  return (
    <div>
      <Row>	  
        {Object.keys(products).map(key =>
          <Col key={key} sm={6} lg={6} xl={4} className="mb-4">
            <Card className="border-0 shadow-sm" onClick={() => onSelect && onSelect(productGroupId, key)} style={{ cursor: 'pointer' }}>
              <Card.Body>
                <ImageItem item={products[key]} />
              </Card.Body>
            </Card>
          </Col>
        )}
      </Row>
    </div>
  );
};

Products.propTypes = {
  orderByWeight: PropTypes.bool.isRequired,
  productGroup: PropTypes.shape({
    name: PropTypes.string.isRequired,
    products: PropTypes.object.isRequired,
  }),
  onSelect: PropTypes.func,
};

export default Products;