import React from 'react';
import styled from 'styled-components';
import { ThemedButton } from '../common/Themed';

const CircleButton = styled(ThemedButton)`
  padding: 8px 10px;
  border-radius: 50%;
`;

const QuantityButton = ({
  children,
  ...rest         // Get all props passed in and spread on button
}) => {

  return (
    <CircleButton
      className="rounded-circle"
      size="sm"
      {...rest}
    >
      {children}
    </CircleButton>
  );
};

export default QuantityButton;