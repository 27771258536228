import React from 'react';
import PropTypes from 'prop-types';
import Numeral from 'numeral';
import { Card, Col, Row } from 'react-bootstrap';

const ItemizedCard = ({ items }) => {
  const styles = {
    body: {
      padding: '1.8rem 2.8rem',
    },
    itemText: {
      position: 'relative',
      top: 13,
    },
  };

  return (
    <Card className="border-0 shadow-sm">
      <Card.Body style={styles.body}>
        <Row className="pt-3 text-uppercase">
          <Col xs={9}>
            <strong>Item</strong>
          </Col>
          <Col xs={3} className="text-right">
            <strong>Price</strong>
          </Col>
        </Row>

        <Row>
          <Col>
            <hr />
          </Col>
        </Row>

        {Object.keys(items).map(id => {
          const item = items[id];

          return (
            <Row key={id} className="mb-2">
              <Col xs={9}>
                <div className="d-flex justify-content-left">
                  <button
                    className="rounded-circle text-center mr-4 border"
                    style={{
                      width: 50,
                      height: 50,
                      cursor: 'default',
                    }}
                  >
                    {item.quantity > 99 ? '99+' : item.quantity.toString()}
                  </button>
                  <span style={styles.itemText}>{item.name}</span>
                </div>
              </Col>
              <Col
                className="text-right"
                style={styles.itemText}
                xs={3}
              >
                {Numeral(item.price).format('$0,0.00')}
              </Col>
            </Row>
          );
        })}
      </Card.Body>
    </Card>
  );
};

ItemizedCard.propTypes = {
  items: PropTypes.object.isRequired,
};

export default ItemizedCard;