import React from 'react';
import PropTypes from 'prop-types';
import styled, { useTheme } from 'styled-components';
import * as fcc from '../../common/fcc';
import { Alert } from 'react-bootstrap';
import { ThemedSpinner } from '../../common/Themed';

const PaymentType = {
  Card: 'cc',
  GooglePay: 'google',
  ApplePay: 'apple',
};

const PaymentContainer = styled.div`
  & iframe {
    width: 100%;
    height: ${props => props.height}px;
  }
`;

const PaymentControl = ({
  paymentType,
  total,
  onLoaded,
  onPay,
}) => {
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState();
  const [iframeSession, setIframeSession] = React.useState();
  const [height, setHeight] = React.useState(0);
  const { iframe, sessionKey } = iframeSession || {};
  const { primaryColor } = useTheme();

  const styles = `
    .fp-apple-pay-button {
      padding: 10px 0;
      height: 40px;
      width: 100%;
    }
    input:focus {
      border-color: ${primaryColor};
      box-shadow: none;
    }
    input:hover {
      border-color: ${primaryColor};
    }
    button {
      background-color: ${primaryColor};
      border-color: ${primaryColor};
      color: #fff;
      padding: 6px 0;
      width: 100%;
    }
    button:focus {
      background-color: ${primaryColor};
      border-color: ${primaryColor};
    }
    button:hover {
      background-color: ${primaryColor};
      border-color: ${primaryColor};
      color: #fff;
    }
  `;

  React.useEffect(() => {
    const handleCreditCardMessage = (e) => {
      const message = e.data;
      const data = message.data;

      // These are messages from react dev tools
      if (!message.type) {
        return;
      }

      switch(message.type) {
        case 1:
        case 4:
          // No work needed
          break;

        case 2:
          setHeight(data.height);
          break;

        case 3:
          const {
            CardIssuer: issuer1,
            MaskedCardNumber: cardNumber1,
            
            // This is what FreedomPay returns for ApplePay
            cardIssuer: issuer2,
            maskedCardNumber: cardNumber2,
          } = data.attributes
            .reduce((catalog, keyval) => ({
              ...catalog,
              [keyval.Key]: keyval.Value,
            }), {});

          const issuer = issuer1 || issuer2;
          const cardNumber = cardNumber1 || cardNumber2;
          const [paymentKey] = data.paymentKeys;

          onPay({
            payment: {
              paymentKey,
              issuer,
              cardNumber,
              sessionKey,
              last4: cardNumber ? cardNumber.slice(cardNumber.length - 4) : 'XXXX',
              type: PaymentType[paymentType],
            }
          });

          break;
        
        default:
          console.warn(`Message type ${message.type} not supported`);
          break;
      }
    };

    window.addEventListener('message', handleCreditCardMessage);

    return () => window.removeEventListener('message', handleCreditCardMessage);
  }, [sessionKey, paymentType, onPay, onLoaded, loading]);

  React.useEffect(() => {
    const fetchIframe = async () => {
      try {
        const type = PaymentType[paymentType];
        const result = await fcc.init({ type, total, styles });
        setIframeSession(result);
      } catch (err) {
        console.error(err);
        setError('Failed to load payment component. Try refreshing your browser. If the issue persists, contact admin.');
      }

      setLoading(false);
    };

    fetchIframe();
  }, [paymentType, total, styles]);

  if (loading && !iframe) {
    return (
      <div className="text-center">
        <ThemedSpinner animation="border" />
      </div>
    );
  }

  // If there's an error, try loading this first
  if (error) {
    return (
      <Alert variant="danger" className="mb-0">
        <i className="fa fa-times-circle" /> {error}
      </Alert>
    );
  }

  return (
    <>
      <PaymentContainer
        height={height}
        dangerouslySetInnerHTML={{ __html: iframe }}
      />
    </>
  );
};

PaymentControl.propTypes = {
  paymentType: PropTypes.oneOf(['Card', 'GooglePay', 'ApplePay']),
  total: PropTypes.number.isRequired,
  onLoaded: PropTypes.func,
  onPay: PropTypes.func.isRequired,
};

export default PaymentControl;