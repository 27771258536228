import React from 'react';
import IdleTimer from 'react-idle-timer';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { Route, Redirect, Switch } from 'react-router-dom';
import { compose } from 'recompose';
import { GoogleAnalyticsProvider, withCheck, withMetadata, withSettings } from './common/providers';
import { withRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { Checkout } from './checkout';
import { Thankyou } from './thankyou';
import { Welcome } from './home';
import { Menu, ProductGroupList } from './ordering';
import { Connect } from './auth';
import { Pay } from './pay';
import { Unauthorized } from './error';
import { AuthRoute, Loading, MenuTopNav } from './common/components';
import { ToastProvider } from './common/providers';

import 'react-toastify/dist/ReactToastify.css';
import './App.scss';

const ContentContainer = styled.div`
  padding-top: 80px;
`;

function App({
  check,
  history,
  location,
  settings,
  revCenter,
}) {
  const [showKickedOut, setShowKickedOut] = React.useState(false);
  const idleTimer = React.useRef();

  const handleIdle = () => {
    if (location.pathname === '/' || location.pathname === '/settings') {
      return;
    }

    setShowKickedOut(true);

    setTimeout(() => {
      setShowKickedOut(false);
      history.push('/');
    }, 2000);
  };

  // TODO: refactor components to use styled components
  //       theme to prevent injecting settings
  //       everywhere.
  const theme = {
    primaryColor: revCenter?.color || '#C0412D',
  };

  // TODO: introduce this in Kiosk mode
  const enableTimer = false;

  return (
    <GoogleAnalyticsProvider>
      <div className="app">

        <Helmet>
          <title>{revCenter?.name || 'Kiosk'}</title>
        </Helmet>

        <ThemeProvider theme={theme}>
          <ToastProvider>

            {enableTimer &&
              <IdleTimer
                ref={idleTimer}
                element={document}
                onIdle={handleIdle}
                timeout={1000 * settings.timeout}
              />
            }

            <Loading
              icon="sign-out-alt"
              show={showKickedOut}
              text="Timed Out"
            />

            <MenuTopNav />

            <ContentContainer>

              <Switch>

                <AuthRoute exact path="/" component={Welcome} valid={!!settings.token}/>

                <Route path="/connect" component={Connect} />

                <AuthRoute
                  exact
                  path="/menus/:menuId"
                  component={ProductGroupList} 
                  validations={[
                    {
                      valid: !!settings.token,
                      redirectUrl: '/',
                    },
                    {
                      valid: !check.sent,
                      redirectUrl: '/pay',
                    },
                  ]}
                />

                <AuthRoute
                  path="/menus/:menuId/productgroups/:productGroupId"
                  component={Menu} 
                  validations={[
                    {
                      valid: !!settings.token && !!check.guests.length,
                      redirectUrl: '/',
                    },
                    {
                      valid: !check.sent,
                      redirectUrl: '/pay',
                    },
                  ]}
                />

                <AuthRoute
                  path="/checkout"
                  component={Checkout}
                  validations={[
                    {
                      valid: !!settings.token && !!check.totalQuantity,
                      redirectUrl: '/',
                    },
                    {
                      valid: !check.sent,
                      redirectUrl: '/pay',
                    },
                  ]}
                />

                <AuthRoute
                  path="/pay"
                  component={Pay}
                  valid={!!settings.token && !!check.totalQuantity}
                  redirectUrl="/"
                />
          
                <Route path="/thankyou" component={Thankyou} />
                <Route path="/error" component={Unauthorized} />
                <Redirect path="*" to="/" />

              </Switch>

            </ContentContainer>

          </ToastProvider>
        </ThemeProvider>
      </div>
    </GoogleAnalyticsProvider>
  );
}

export default compose(
  withCheck,
  withMetadata,
  withRouter,
  withSettings,
)(App);